@import './asset/scss/_themes-vars.module.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* @font-face {
  font-family: "EpilogueVariableFont";
  src: local("EpilogueVariableFont"),
    url("./asset/fonts/Epilogue/Epilogue-VariableFont_wght.ttf") format("truetype");
} */

@font-face {
  font-family: "InterVariableFont";
  src: local("InterVariableFont"),
    url("./asset/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}



// @font-face {
//   font-family: "PoppinsVariableFont";
//   src: local("poppinsVariableFont"),
//     url("./asset/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
// }



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.customInput_padding > .MuiInputBase-root{
    padding: 5px 10px;

}

.customInput_padding > .MuiInputBase-root::before{
    border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding > .MuiInputBase-root::after{
    border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding > .MuiInput-root.Mui-error::before{
    border-bottom: 2px solid $errorMain !important;
}

.customInput_padding > .MuiInput-root.Mui-error::after{
    border-bottom: 2px solid $errorMain !important;
}

.swal2-container {
  z-index: 99999;
}
.swal2-confirm, .swal2-cancel {
  outline: none !important;
  box-shadow: none !important;
}

.cancelbtn {
  padding: 0 35px;
  border-radius: 5px !important;
  color: $buttonColor;
  height: 3rem;
  background-color: #28389110;
}

.chat-scroll {
  .ps__rail-x {
    display: none !important;
  }
}

.Textarea_design {
  font-family: "Inter" !important;
  font-size: 16px !important;
}

.Textarea_design.disabled {
  color: #888; /* Change the text color */
  border: 1px solid #ccc; /* Change the border color */
}

.MuiMultiSectionDigitalClock-root {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(187, 187, 187);
  }
}

//table body border removing
.MuiTableCell-root {
  border: 0
}
.legacy-search {
  width: 12%;
  display: flex;
  cursor: pointer;
}
.legacy-search-div {
  cursor: pointer;
  width: 100%;
  height: 3.1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  margin-top: 2.6rem;
  border: 1px solid #ccc;
  background-color: #dcdbdf;
  pointer-events: none;
}
.profile-step {
  .MuiStepLabel-root .Mui-completed {
    color: green !important;
  }

  .MuiStepLabel-label.Mui-completed.MuiStepLabel-aternativeLabel {
    color: grey.500 !important;
  }

  .MuiStepLabel-root .Mui-active {
    color: #547DBF;
  }

  .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
    color: white !important;
  }

  .MuiStepLabel-root .Mui-active .MuiStepIcon-text {
    fill: white !important;
  }

  .MuiStepLabel-labelContainer>span {
    font-size: 1.2rem !important;
    font-weight: 550 !important;
    padding: 2rem 0 !important;
  }

  .MuiStepLabel-iconContainer .MuiSvgIcon-root {
    font-size: 2.5rem !important;
  }
}

.mail-list-li {
  align-items:"flex-start" !important;
}